import { isValidElement } from "react"

import { stringify } from "flatted"

import { type Code } from "./types"

export const formatCode = (code: object) => stringify(code, undefined, 2)

export const formatNestedObject = (code: unknown, indent = ""): string => {
  if (typeof code !== "object" || code === null) {
    return JSON.stringify(code)
  }

  const nextIndent = `${indent}  `
  const entries = Object.entries(code)

  if (entries.length === 0) return "{}"

  const formattedEntries = entries.map(([key, value]) => {
    const formattedValue = formatNestedObject(value, nextIndent)
    return `${nextIndent}"${key}": ${formattedValue}`
  })

  return `{\n${formattedEntries.join(",\n")}\n${indent}}`
}

const parseJSON = (code: Code) => {
  let JSONString: object | null = null

  if (typeof code === "string") {
    try {
      JSONString = JSON.parse(code) as object
    } catch (e) {
      JSONString = null
    }
  }

  return JSONString
}

const parseNumber = (code: Code) => {
  let number: number | null = null

  if (typeof code === "string") {
    const parsedNumber = parseFloat(code)
    const isNumber =
      !Number.isNaN(parsedNumber) && parsedNumber.toString() === code
    number = isNumber ? parsedNumber : null
  }

  return number
}

const parseReactNode = (code: Code) => {
  let children: string | null = null

  if (isValidElement(code) && "children" in code.props) {
    children = (code.props as unknown as { children: string }).children
  }

  return children
}

export const parseUrl = (urlString: string): string => {
  try {
    const url = new URL(urlString)
    const endpointExtracted = {
      endpoint: url.hostname === "0.0.0.0" ? "localhost" : (url.hostname ?? ""),
      port: url.port ?? "",
    }
    return `${endpointExtracted.endpoint}:${endpointExtracted.port}`
  } catch (error) {
    return urlString
  }
}

export const separateHostnameAndPort = (
  combinedString: string,
): { hostname: string; port: string } => {
  const [hostname, port] = combinedString.split(":")
  return { hostname, port: port ?? "" }
}

export const joinHostnameAndPort = (hostname: string, port: string): string =>
  `${hostname}:${port}`

export const parseCode = (code: Code) =>
  parseJSON(code) ?? parseNumber(code) ?? parseReactNode(code) ?? code

export const constructEndpointUrl = (value: string) => {
  if (value.startsWith("http://") || value.startsWith("https://")) {
    return value
  }

  // Check if the endpoint is localhost or an IP address
  if (
    value.startsWith("localhost") ||
    /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(value)
  ) {
    return `http://${value}`
  }

  // For all other cases, default to HTTPS
  return `https://${value}`
}
