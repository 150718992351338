import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/vercel/path0/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/AppShell/LoadUserData.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/AppShell/MiscClientLogic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/PlaygroundContext/PlaygroundContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/RunContext/RunContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/TeamContext/TeamContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/contexts/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/ToastContext/ToastContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/UserContext/UserContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/WorkspaceContext/WorkspaceContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/providers/NextAuthProvider/NextAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/providers/ReactQueryProvider/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/assets/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/assets/fonts.ts\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-dm-mono\",\"weight\":\"400\"}],\"variableName\":\"fontDMMono\"}");
